<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    displayLegend: {
      type: Boolean,
      default: true
    },
    moneyFormat: {
      type: Boolean,
      default: false
    },
    percentFormat: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.renderChart(this.chartdata, {
      legend: {
        display: this.displayLegend,
        position: 'bottom',
        labels: {
          boxWidth: 15
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        callbacks: {
          label: ((tooltipItem, data) => {
            let keyName = Object.keys(data.datasets[tooltipItem.datasetIndex]._meta);

            let hiddenLabels = data.datasets[tooltipItem.datasetIndex]._meta[keyName].data.map((label) => {
              return {index: label._index, hidden: label.hidden }
            });

            let filterHiddenLabels = (value, index) => {
              let label = hiddenLabels.find(h => h.index === index) || { hidden: false };
              return !label.hidden;
            };

            const sum = data.datasets[tooltipItem.datasetIndex].data.filter(filterHiddenLabels).reduce((acc, curr) => parseFloat(acc) + parseFloat(curr))
            const valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            const percent = (100 * valor / (sum === 0 ? 1 : sum))
            const label = data.labels[tooltipItem.index];

            if (this.moneyFormat) {
              return `${label}: R$ ${Number(valor || 0).toLocaleString('pt-BR', {minimumFractionDigits: 2})} (${percent.toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}%)`;
            } else if (this.percentFormat) {
              return `${label}: ${(percent || 0).toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}%`
            } else {
              return `${label}: ${(Number(valor) || 0).toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})} (${percent.toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}%)`
            }
          })
        }
      }
    })
  }
}
</script>
