<template>
  <div class="animated fadeIn">
    <b-card-group class="mb-4">
      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-8 p-0 mr-2">
        <div slot="header">
          <strong> Filtros </strong>
        </div>
        <div class="p-1 mt-2">
          <b-row class="mb-1">
            <b-col sm="12" md="6" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="changePeriodo" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Hoje">Hoje</b-form-radio>
                  <b-form-radio class="mx-0" value="Ontem">Ontem</b-form-radio>
                  <b-form-radio class="mx-0" value="Semana">Semana</b-form-radio>
                  <b-form-radio class="mx-0" value="Mes">Mês</b-form-radio>
                  <b-form-radio class="mx-0" value="Periodo">Período</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
            <b-col sm="12" md="6">
              <div class="custom-control custom-checkbox pt-1">
                <input type="checkbox" class="custom-control-input" id="chkDesconsiderarPecas" v-model="filtro.fields.desconsiderarPecas">
                <label class="custom-control-label" for="chkDesconsiderarPecas">Desconsiderar devoluções em "Peças"</label>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col sm="3" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.gestao" :options="filtro.listas.gestao" @change="filterLojas"></b-form-select>
            </b-col>
            <b-col sm="3" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.centres" :options="filtro.listas.centresFiltradosPorGestao"></b-form-select>
            </b-col>
            <b-col sm="3" class="mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda"></b-form-select>
            </b-col>
            <b-col sm="2" class="pl-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.tipoInformacao" :options="filtro.listas.tipoInformacao" :disabled="filtro.fields.exibicao === 'Tabelas'"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6" sm="6" md="3" class="pr-md-0 pr-1 mb-1 mb-md-0">
              <b-form-input :plain="true" v-model="filtro.fields.dataIni" type="date" @change="dataChanged"></b-form-input>
            </b-col>
            <b-col cols="6" sm="6" md="3" class="pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0">
              <b-form-input :plain="true" v-model="filtro.fields.dataFim" type="date" @change="dataChanged"></b-form-input>
            </b-col>
            <b-col sm="3" class="mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.classificacao1" :options="classificacoes1"></b-form-select>
            </b-col>
            <b-col sm="2" class="pl-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.classificacao2" :options="classificacoes2"></b-form-select>
            </b-col>
            <b-col sm="1" class="pl-md-0">
              <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-4 p-0">
        <div slot="header">
          <strong> Layout </strong>
        </div>
        <div class="p-1">
          <b-row class="mb-1">
            <b-col sm="12" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.exibicao" @change="exibicaoChanged" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Graficos">Gráficos</b-form-radio>
                  <b-form-radio class="mx-0" value="Tabelas">Tabelas</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-1">
            <b-col sm="12">
              <b-form-select :plain="true" v-model="filtro.fields.agrupamento" :options="filtro.listas.agrupamentos" :disabled="filtro.fields.centres !== 0"></b-form-select>
            </b-col>
          </b-row> -->
        </div>
      </b-card>
    </b-card-group>

    <b-row v-if="filtro.fields.exibicao === 'Graficos'">
      <b-col md="4" sm="12" v-for="(chart, index) in charts" :key="index">
        <b-card class="mr-md-2">
          <div slot="header" class="text-black">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto">
                <strong>{{ chart.title }}</strong>
              </div>
              <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image(chart.id, chart.title)" title="Exportar"></i>
              </div>
            </div>
          </div>
          <doughnut :chartdata="chart.options" :height="280" :chart-id="chart.id" :displayLegend="true" :moneyFormat="verifyTipoInformacao" :percentFormat="verifyTipoInformacao" v-if="loaded" />
        </b-card>
      </b-col>
    </b-row>

    <div v-if="filtro.fields.exibicao === 'Tabelas'">
      <b-row v-for="(item, index) in items" :key="index">
        <b-col cols="12" sm="12" lg="12">
          <c-table :table-data="item.tabela" :fields="fields" striped small :caption="item.name"></c-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import API from '@/core/api';
import cTable from '@/core/components/Table.vue'
import Doughnut from '@/core/components/charts/Doughnut.vue'
import session from '@/shared/session';
import lojasPorGestaoUniMixin from '@/core/mixins/lojasPorGestaoUni.mixin.js';
export default {
  name: 'MixProdutosVendidos',
  components: {
    cTable,
    Doughnut
  },
  mixins:[
    lojasPorGestaoUniMixin
  ],
  data: () => {
    return {
      loaded: false,
      filtro: {
        listas: {
          gestao: session.get('gestoes'),
          centres: session.get('lojas'),
          centresDefault: session.get('lojas'),
          formaVenda: session.get('formas'),
          classificacao1: [],
          classificacao2: [],
          agrupamentos: [{value: 1, text: 'Gestão/Loja'}, {value: 2, text: 'Gestão/Vendedor'}, {value: 3, text: 'Grupo Empresarial/Loja'}, {value: 4, text: 'Grupo Empresarial/Vendedor'}, {value: 5, text: 'Grupo Empresarial/Gestão'}],
          tipoInformacao: [{value: null, text: 'Todos', disabled: true}, {value: 'vendas', text: 'Vendas'}, {value: 'pecas', text: 'Peças'}],
          centresFiltradosPorGestao: []
        },
        fields: {
          periodo: 'Hoje',
          gestao: 0,
          centres: 0,
          formaVenda: 0,
          dataIni: moment().format('YYYY-MM-DD'),
          dataFim: moment().format('YYYY-MM-DD'),
          classificacao1: 'GRUPO',
          classificacao2: 'SUBGRUPO',
          tipoInformacao: 'vendas',
          agrupamento: 1,
          exibicao: 'Graficos',
          desconsiderarPecas: false
        }
      },
      items: [],
      fields: [
        {key: 'n', label: 'Nº', sortable: true},
        {key: 'grupo', label: 'Grupo', sortable: true},
        {key: 'estoque', label: 'Estoque', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'compra', label: 'Compra', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'vendas', label: 'Vendas R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'pecas', label: 'Peças', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'perc', label: '%', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'acumulado', label: 'Acum.', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'abc', label: 'ABC.', sortable: true}
      ],
      clienteInfo: {},
      charts: []
    }
  },
  computed: {
    tipoInformacao: function () {
      const {tipoInformacao: tipoInfoList} = this.filtro.listas;
      const {tipoInformacao} = this.filtro.fields;
      const info = tipoInfoList.find(i => i.value && i.value === tipoInformacao);
      return (info && info.text) || '';
    },
    verifyTipoInformacao() {
      const isCurrency = ['vendas'];
      return isCurrency.includes(this.filtro.fields.tipoInformacao) ? true : false
    },
    classificacoes1: function() {
      const { classificacao2 } = this.filtro.fields
      return [ ...this.filtro.listas.classificacao1 ].filter(c => c.value !== classificacao2)
    },
    classificacoes2: function() {
      const { classificacao1 } = this.filtro.fields
      return [ ...this.filtro.listas.classificacao2 ].filter(c => c.value !== classificacao1)
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.loaded = false;

          const mixAPI = new API('api/mix-produtos');
          const {data} = await mixAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.items = this.parseList(data.data)

          const { exibicao } = this.filtro.fields;
          if (exibicao === 'Tabelas') {
            this.filtro.listas.tipoInformacao[0].disabled = false;
            this.filtro.fields.tipoInformacao = null;
          }

          this.loadCharts();
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getClassificacoes() {
      const fixedClassificacoes = [
        {value: 'GRUPO', text: 'GRUPO'},
        {value: 'SUBGRUPO', text: 'SUBGRUPO'}
      ];

      const classificacoesAPI = new API('api/mix-classificacoes');
      const classificacoes = await classificacoesAPI.get();
      this.filtro.listas.classificacao1 = classificacoes.data.data.map(c => {
        return {
          value: c.fd_descricao.toUpperCase(),
          text: c.fd_descricao.toUpperCase()
        }
      });
      this.filtro.listas.classificacao2 = classificacoes.data.data.map(c => {
        return {
          value: c.fd_descricao.toUpperCase(),
          text: c.fd_descricao.toUpperCase()
        }
      });

      this.filtro.listas.classificacao1.push(...fixedClassificacoes)
      this.filtro.listas.classificacao1.unshift({value: null, text: 'Selecione', disabled: true})

      this.filtro.listas.classificacao2.push(...fixedClassificacoes)
      this.filtro.listas.classificacao2.unshift({value: null, text: 'Selecione', disabled: true})
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    dataChanged() {
      this.filtro.fields.periodo = 'Periodo';
    },
    exibicaoChanged() {
      const { tipoInformacao, exibicao } = this.filtro.fields;
      if (exibicao === 'Graficos') {
        this.filtro.listas.tipoInformacao[0].disabled = false;
        this.filtro.fields.tipoInformacao = null;
      } else {
        this.loaded = false;
        if (!tipoInformacao) {
          this.filtro.fields.tipoInformacao = 'vendas';
        }
        this.loadCharts()
      }
    },
    loadCharts() {
      const { tipoInformacao } = this.filtro.fields
      const charts = [];
      this.items.forEach((i, index) => {
        const labels = i.grafico.map(r => r.grupo).join().split(',')
        const chart = {
          id: `pie-chart-${index}`,
          title: i.name,
          options: {
            labels: labels,
            datasets: [
              {
                backgroundColor: this.dynamicColors(labels.length),
                data: i.grafico.map(r => r[tipoInformacao]).join().split(',')
              }
            ]
          }
        }
        charts.push(chart)
      })
      this.charts = charts;
      this.loaded = true;
    },
    changePeriodo(periodo) {
      switch(periodo) {
        case 'Hoje': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Ontem': {
          this.filtro.fields.dataIni = moment().subtract(1, 'day').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().subtract(1, 'day').format('YYYY-MM-DD');
          break;
        }
        case 'Semana': {
          this.filtro.fields.dataIni = moment().startOf('week').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('week').format('YYYY-MM-DD');
          break;
        }
        case 'Mes': {
          this.filtro.fields.dataIni = moment().startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('month').format('YYYY-MM-DD');
          break;
        }
        default: return;
      }
      this.search();
    },
    export2image: function (id, name) {
      // Set White Background
      let chart = document.getElementById(id)
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name || 'Grafico'
      link.href = canvas
      link.click()
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        centres,
        formaVenda
      } = filters;

      const lojas = this.filtro.listas.centres.map(c => c.value).join(',')

      filter.centres = centres === 0 ? lojas : centres;
      filter.formaVenda = formaVenda !== 0 ? [formaVenda] : 0;
      return filter;
    },
    parseList(data) {
      if (data && data.length === 0) {
        return [];
      }

      let acumulado = 0;
      data.map(r => {
        r.tabela.reduce((acc, current) => {
          if (acc) {
            acc.acumulado = Number(acc.perc)
            acumulado = acc.acumulado
          }
          current.acumulado = (Number(acumulado) + Number(current.perc))
          acumulado = current.acumulado
        })
        return r;
      })

      return data.map(r => {
        r.tabela.map(d => {
          d.promocao = d.promocao === 0 ? 'Não' : 'Sim'
          d.estoque = parseInt(d.estoque)
          d.compra = parseInt(d.compra)
          d.pecas = parseInt(d.pecas)
          d.perc = Number(d.perc).toFixed(2)
          d.acumulado = Number(d.acumulado || 100).toFixed(2)
          d.abc = this.getABC(d.acumulado - d.perc)
          return d
        });
        return r
      })
    },
    dynamicColors(count) {
      let result = [ ...this.$store.state.colors ];
      for (let i = 0; i < count; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);

        result.push("rgb(" + r + "," + g + "," + b + ",.7)")
      }
      return result;
    },
    getABC(acumulado) {
      if (acumulado < 70) {
        return 'A'
      } else if (acumulado > 90) {
        return 'C'
      }
      return 'B'
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    }
  },
  async created() {
    await this.getClassificacoes()
    this.search();
  }
}
</script>
