var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card-group",
        { staticClass: "mb-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "col-md-8 p-0 mr-2",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Filtros ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1 mt-2" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-md-block",
                          attrs: { sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  on: { change: _vm.changePeriodo },
                                  model: {
                                    value: _vm.filtro.fields.periodo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "periodo",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.periodo"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Hoje" }
                                    },
                                    [_vm._v("Hoje")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Ontem" }
                                    },
                                    [_vm._v("Ontem")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Semana" }
                                    },
                                    [_vm._v("Semana")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Mes" }
                                    },
                                    [_vm._v("Mês")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Periodo" }
                                    },
                                    [_vm._v("Período")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "custom-control custom-checkbox pt-1"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filtro.fields.desconsiderarPecas,
                                  expression: "filtro.fields.desconsiderarPecas"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "chkDesconsiderarPecas"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.filtro.fields.desconsiderarPecas
                                )
                                  ? _vm._i(
                                      _vm.filtro.fields.desconsiderarPecas,
                                      null
                                    ) > -1
                                  : _vm.filtro.fields.desconsiderarPecas
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.filtro.fields.desconsiderarPecas,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "desconsiderarPecas",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "desconsiderarPecas",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "desconsiderarPecas",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "chkDesconsiderarPecas" }
                              },
                              [_vm._v('Desconsiderar devoluções em "Peças"')]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 mb-1 mb-md-0",
                          attrs: { sm: "3" }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.gestao
                            },
                            on: { change: _vm.filterLojas },
                            model: {
                              value: _vm.filtro.fields.gestao,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "gestao", $$v)
                              },
                              expression: "filtro.fields.gestao"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 mb-1 mb-md-0",
                          attrs: { sm: "3" }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options:
                                _vm.filtro.listas.centresFiltradosPorGestao
                            },
                            model: {
                              value: _vm.filtro.fields.centres,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "centres", $$v)
                              },
                              expression: "filtro.fields.centres"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1 mb-md-0", attrs: { sm: "3" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.formaVenda
                            },
                            model: {
                              value: _vm.filtro.fields.formaVenda,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "formaVenda", $$v)
                              },
                              expression: "filtro.fields.formaVenda"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pl-md-0", attrs: { sm: "2" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.tipoInformacao,
                              disabled: _vm.filtro.fields.exibicao === "Tabelas"
                            },
                            model: {
                              value: _vm.filtro.fields.tipoInformacao,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.filtro.fields,
                                  "tipoInformacao",
                                  $$v
                                )
                              },
                              expression: "filtro.fields.tipoInformacao"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pr-1 mb-1 mb-md-0",
                          attrs: { cols: "6", sm: "6", md: "3" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { plain: true, type: "date" },
                            on: { change: _vm.dataChanged },
                            model: {
                              value: _vm.filtro.fields.dataIni,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "dataIni", $$v)
                              },
                              expression: "filtro.fields.dataIni"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0",
                          attrs: { cols: "6", sm: "6", md: "3" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { plain: true, type: "date" },
                            on: { change: _vm.dataChanged },
                            model: {
                              value: _vm.filtro.fields.dataFim,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "dataFim", $$v)
                              },
                              expression: "filtro.fields.dataFim"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1 mb-md-0", attrs: { sm: "3" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.classificacoes1
                            },
                            model: {
                              value: _vm.filtro.fields.classificacao1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.filtro.fields,
                                  "classificacao1",
                                  $$v
                                )
                              },
                              expression: "filtro.fields.classificacao1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pl-md-0", attrs: { sm: "2" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.classificacoes2
                            },
                            model: {
                              value: _vm.filtro.fields.classificacao2,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.filtro.fields,
                                  "classificacao2",
                                  $$v
                                )
                              },
                              expression: "filtro.fields.classificacao2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pl-md-0", attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-card",
            {
              staticClass: "col-md-4 p-0",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Layout ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-md-block", attrs: { sm: "12" } },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  on: { change: _vm.exibicaoChanged },
                                  model: {
                                    value: _vm.filtro.fields.exibicao,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "exibicao",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.exibicao"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Graficos" }
                                    },
                                    [_vm._v("Gráficos")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Tabelas" }
                                    },
                                    [_vm._v("Tabelas")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.filtro.fields.exibicao === "Graficos"
        ? _c(
            "b-row",
            _vm._l(_vm.charts, function(chart, index) {
              return _c(
                "b-col",
                { key: index, attrs: { md: "4", sm: "12" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mr-md-2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-black",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex-inline mr-auto" },
                              [_c("strong", [_vm._v(_vm._s(chart.title))])]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex-inline ml-auto" },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-download text-primary cursor-pointer",
                                  attrs: { title: "Exportar" },
                                  on: {
                                    click: function($event) {
                                      return _vm.export2image(
                                        chart.id,
                                        chart.title
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm.loaded
                        ? _c("doughnut", {
                            attrs: {
                              chartdata: chart.options,
                              height: 280,
                              "chart-id": chart.id,
                              displayLegend: true,
                              moneyFormat: _vm.verifyTipoInformacao,
                              percentFormat: _vm.verifyTipoInformacao
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.filtro.fields.exibicao === "Tabelas"
        ? _c(
            "div",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-row",
                { key: index },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "12", lg: "12" } },
                    [
                      _c("c-table", {
                        attrs: {
                          "table-data": item.tabela,
                          fields: _vm.fields,
                          striped: "",
                          small: "",
                          caption: item.name
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }